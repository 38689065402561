// Variable overrides
$footer-height:                       100px !default;
$sidebar-dark-brand-bg: white;
// use nmdc colors
$white:  #fff !default;
$black:  #000015 !default;

$primary-base:  #4f3b80 !default;
$primary-100:   #d6baff !default;
$primary-200:   #b99fee !default;
$primary-300:   #b99fee !default;
$primary-400:   #9e84d1 !default;
$primary-500:   #9e84d1 !default;
$primary-600:   #836bb5 !default;
$primary-700:   #836bb5 !default;
$primary-800:   #69529a !default;
$primary-900:   #69529a !default;

$primary-dark:  #1c104e !default;
$primary:       #4f3b80 !default;
$primary-50:    #9e84d1 !default;
$primary-25:    #ccc7f6 !default;

// $secondary-base:  #ed5338 !default;

// $secondary-100:   #ffe1b8 !default;
// $secondary-200:   #ffc49d !default;
// $secondary-300:   #ffc49d !default;
// $secondary-400:   #ffa782 !default;
// $secondary-500:   #ffa782 !default;
// $secondary-600:   #ff8b69 !default;
// $secondary-700:   #ff8b69 !default;
// $secondary-800:   #ff6f50 !default;
// $secondary-900:   #ff6f50 !default;

// $secondary-dark:  #ad1008 !default;
// $secondary:       #ed5338 !default;
// $secondary-50:    #ffc49d !default;
// $secondary-25:    #ffe1b8 !default;


$danger-dark:  #bf0025 !default;
$danger:       #ff5252 !default;
$danger-50:    #ffa99e !default;
$danger-25:    #ffe4d5 !default;

$info-dark:  #0064ba !default;
$info:       #00aae7 !default;
$info-50:    #95e8ff !default;
$info-25:    #d4ffff !default;

$success-dark:  #326b00 !default;
$success:       #689f38 !default;
$success-50:    #bbf386 !default;
$success-25:    #f4ffbd !default;

$warning-dark:  #bb5900 !default;
$warning:       #fb8c00 !default;
$warning-50:    #ffdf67 !default;
$warning-25:    #ffff9e !default;

$light: #F4F5F8 !default;
$dark: #222428 !default;